
@media print {

	html.datatable-print {

		.loading-overlay {
			display: none !important;
		}

		app-scaffold {

			mat-drawer {
				display: none;
			}

			mat-drawer-content {
				margin-left: 0 !important;

				app-topbar mat-toolbar {

					background: transparent;
					color: black;
					
					button.menu-toggle,
					button.account-menu {
						display: none;
					}
					
				}

				.main-content {
					mat-toolbar {
						.page-filter,
						.page-actions {
							visibility: hidden;
							width: 0 !important;
						}

					}
				}

			}

		}


	}

}
