
@use '~@angular/material' as mat;
@use 'sass:map';

@mixin app-breadcrumbs-theme($theme) {

	$color-config: mat.get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');

	app-breadcrumbs {

		&::before {
			background: linear-gradient(90deg, mat.get-color-from-palette($primary-palette, 500), transparent)
		}

	}

}