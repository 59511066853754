html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.width-container {
	max-width: 532px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	box-sizing: border-box;

	&.scrollable {
		overflow-y: auto;
	}
}

mat-toolbar.mat-toolbar {
	.width-container {
		max-width: calc(532px - 32px);
  }
}

.mat-tab-nav-bar, .mat-tab-header {
  letter-spacing: 0.089em;
  text-transform: uppercase;

  .mat-tab-label-active {
    opacity: 1
  }
}

.mat-button-base {
  letter-spacing: 0.089em;
  text-transform: uppercase;
}

.mat-dialog-container {
	position: relative;

	.mat-toolbar[mat-dialog-title] {
			margin: -24px;
			margin-bottom: 24px;
			width: calc(100% + 48px);
	}

	.mat-dialog-actions {
			border-top: 1px solid rgba(0, 0, 0, 0.12);
			padding: 16px 0;
	}
}


// Lightbox edit
.lightbox {

	&.lightbox-shown {
		background-color: rgba(0, 0, 0, 0.85) !important;
	}

	.lightbox-back{
		background-image: none !important;
	
		&::after {
			content: "\e5c4";
			font-family: 'Material Icons Outlined';
			font-weight: normal;
			font-style: normal;
			font-size: 24px;
			line-height: 1;
			letter-spacing: normal;
			text-transform: none;
			display: inline-block;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			text-rendering: optimizeLegibility;
			-webkit-font-smoothing: antialiased;
	
			height: 56px;
			line-height: 56px;
			width: 56px;
			text-align: center;
		}
	}
	.lightbox-close{
		background-image: none !important;
		font-size: 0 !important;
	
		&::after {
			content: "\e5cd";
			font-family: 'Material Icons Outlined';
			font-weight: normal;
			font-style: normal;
			font-size: 24px !important;
			line-height: 1;
			letter-spacing: normal;
			text-transform: none;
			display: inline-block;
			white-space: nowrap;
			word-wrap: normal;
			direction: ltr;
			text-rendering: optimizeLegibility;
			-webkit-font-smoothing: antialiased;
	
			height: 56px;
			line-height: 56px;
			width: 56px;
			text-align: center;
		}
	}

}

@import '~@swimlane/ngx-datatable/themes/material.scss';

// ngx-datatables updates
.ngx-datatable.material {
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
	.datatable-body {
		.datatable-row-wrapper:not(:last-child)  {
			.datatable-body-row {
				border-bottom: 1px solid #e0e0e0;
			}
		}
		.datatable-body-row {
			.datatable-body-cell {
				padding: 8px 16px
			}

			&.clickable {
				cursor: pointer;
			}
		}
	}

	.datatable-header {
		.datatable-header-inner {
			.datatable-header-cell {
				line-height: 22px !important;
				padding: 8px 16px
			}
		}
	}
}

.mat-toolbar {
	.mat-form-field {
		font-size: 14px
	}
}

.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
	top: 0em !important;
}




@import './app/themes/dc-base.theme.scss';