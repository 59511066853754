
// Margin helpers
$i: 256;
@while $i >= 0 {

	.m-#{$i} {
		margin: #{$i}px !important;
	}

	$i: $i - 2;
}
$i: 256;
@while $i >= 0 {

	.mr-#{$i} {
		margin-right: #{$i}px !important;
	}
	.mt-#{$i} {
		margin-top: #{$i}px !important;
	}
	.ml-#{$i} {
		margin-left: #{$i}px !important;
	}
	.mb-#{$i} {
		margin-bottom: #{$i}px !important;
	}

	$i: $i - 2;
}

// Padding helpers
$i: 256;
@while $i >= 0 {

	.p-#{$i} {
		padding: #{$i}px !important;
	}

	$i: $i - 2;
}
$i: 256;
@while $i >= 0 {

	.pr-#{$i} {
		padding-right: #{$i}px !important;
	}
	.pt-#{$i} {
		padding-top: #{$i}px !important;
	}
	.pl-#{$i} {
		padding-left: #{$i}px !important;
	}
	.pb-#{$i} {
		padding-bottom: #{$i}px !important;
	}

	$i: $i - 2;
}

// Width helpers
$i: 0;
@while $i < 512 {

	.w-#{$i} {
		width: #{$i}px !important;
	}
	.minw-#{$i} {
		min-width: #{$i}px !important;
	}
	.maxw-#{$i} {
		max-width: #{$i}px !important;
	}

	.h-#{$i} {
		height: #{$i}px !important;
	}
	.minh-#{$i} {
		min-height: #{$i}px !important;
	}
	.maxh-#{$i} {
		max-height: #{$i}px !important;
	}

	$i: $i + 2;
}

// Icon size helpers
$i: 0;
@while $i < 112 {

	mat-icon.s-#{$i} {
		font-size: #{$i}px !important;
		line-height: #{$i}px !important;
		height: #{$i}px !important;
		width: #{$i}px !important;
	}

	$i: $i + 2;
}


.align-center {
	text-align: center;
}